<template>
  <v-card
    class="mb-1"
  >
    <v-card-title>
      {{service.name}}
      <v-spacer />
      <v-checkbox
        v-model="state"
        @click="handleCheckboxClick"
      />
    </v-card-title>

    <v-card-text
      v-if="serviceItems.length > 0"
    >
      <v-chip-group
        v-model="serviceItemsSelected"
        column
        multiple
        @change="handleChipGroupChange"
      >
        <v-chip
          v-for="(si, sii) in serviceItems"
          :key="`service-item-${sii}`"
          :close="sii === 0"
          active-class="primary"
          close-icon="mdi-cog"
          label
          @click:close="handleChipClose(sii)"
          @click="handleChipClick(sii)"
        >
          {{ si.name }}
        </v-chip>
      </v-chip-group>
    </v-card-text>
    <v-card-text
      v-else
    >
      <v-chip-group
        column
      >
        <v-chip
          label
          disabled
        >
          无需配置服务
        </v-chip>
      </v-chip-group>
    </v-card-text>
    <v-dialog
      v-model="serviceFeeCardDialog"
      persistent
      max-width="600"
    >
      <service-fee-card
        v-if="serviceFeeCardDialog"
        :service="editItem"
        @cancel="handleServiceFeeCardCancel"
        @submit="handleServiceFeeCardSubmit"
      />
    </v-dialog>
  </v-card>
</template>


<script>

  export default {
    components: {
      ServiceFeeCard: () => import('./ServiceFeeCard')
    },
    props: {
      service: {
        type: Object,
        default: () => null
      },
      serviceItems: {
        type: Array,
        default: () => []
      },
      selected: {
        type: Boolean,
        default: () => false
      },
      required: {
        type: Boolean,
        default: () => false
      },
      serviceListSelected: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        state: this.selected,
        serviceFeeCardDialog: false,
        serviceItemsSelected: [],
        frequencySelected: 'null',
        itemEditedIndex: null,
        feeTypeSelected: null,
        editItem: null,
        serviceFrequencyTime: [
          {
            name: this.$vuetify.lang.t('$vuetify.serviceFrequency.weekFrequency'),
            value: 'WEEK_FREQUENCY'
          },
          {
            name: this.$vuetify.lang.t('$vuetify.serviceFrequency.monthFrequency'),
            value: 'MONTH_FREQUENCY'
          },
          {
            name: this.$vuetify.lang.t('$vuetify.serviceFrequency.quarterFrequency'),
            value: 'QUARTER_FREQUENCY'
          }
        ],
        serviceFrequencyPackage: [
          {
            name: this.$vuetify.lang.t('$vuetify.serviceFrequency.seriesFrequency'),
            value: 'SERIES_FREQUENCY'
          },
          {
            name: this.$vuetify.lang.t('$vuetify.serviceFrequency.combinationPackage'),
            value: 'COMBINATION_PACKAGE'
          }
        ],
        defaultService: {
          serviceFrequency: null,
          serviceItem: null,
          serviceType: null,
          feeId: null
        },
        serviceList: [

        ]
      }
    },

    computed: {
    },

    watch: {
      selected: {
        handler: function(newValue) {
          this.state = newValue
        }
      }
    },

    created () {
      this.initialize()
    },

    mounted () {
      if ( this.required ) {
        this.state = this.required
      }
    },

    methods: {

      initialize () {
        if (this.serviceItems.length > 0) {

          this.serviceListSelected.forEach( element => {
            let index = this.serviceItems.findIndex( (ele) => ele.value === element.serviceIterm )
            if (index !== -1) {
              this.serviceItemsSelected.push(index)
              this.serviceList.push(element)
            }
          })
        }
      },

      generatServiceItemData (items = ['HEALTH_REPORT', 'ECG_STANDARD'], setSelected = true) {
        items.forEach( (element) => {
          let rs = Object.assign( {}, this.defaultService)
          rs.serviceIterm = element
          rs.serviceType = this.service.value
          if (element === 'HEALTH_REPORT') {
            rs.serviceFrequency = 'MONTH_FREQUENCY'
          }

          this.serviceList.push(rs)

          if (setSelected) {
            let index = this.serviceItems.findIndex( (ele) => ele.value === element )
            if (index !== -1) {
              this.serviceItemsSelected.push(index)
            }
          }
        })
      },

      removeServiceItem(item) {
        let index = this.serviceList.findIndex( (ele) => ele.serviceIterm === item )
        if (index !== -1) {
          this.serviceList.splice(index, 1)
        }
      },

      handleCheckboxClick () {
        if (this.service.value === 'REPORT_SERVICE') {
          if (this.state) {
            this.generatServiceItemData()
          } else {
            this.serviceList = []
            this.serviceItemsSelected = []
          }
        } else {
          if (this.state ) {
            let rs = Object.assign( {}, this.defaultService)
            rs.serviceType = this.service.value
            this.serviceList.push(rs)
          } else {
            this.serviceList = []
          }
        }

        this.$emit( 'change', this.serviceList)
      },

      handleChipClose (index) {
        if ( this.serviceItemsSelected.includes(index) ) {
            this.editItem = this.serviceList.find( (element) => element.serviceIterm === this.serviceItems[index].value )
            this.serviceFeeCardDialog = true
        }

      },

      handleChipClick (index) {
        if (!this.serviceItemsSelected.includes(index)) {
          this.generatServiceItemData([this.serviceItems[index].value], false)
        } else {
          this.removeServiceItem(this.serviceItems[index].value)
        }
      },

      handleChipGroupChange (data) {
        if (! data.includes(this.itemEditedIndex)) {
          this.itemEditedIndex = null
        }
        if ( data.length <= 0) {
          this.state = false
        } else {
          this.state = true
        }
        this.$emit( 'change', this.serviceList)
      },

      handleServiceFeeCardCancel () {
        this.serviceFeeCardDialog = false
      },

      handleServiceFeeCardSubmit (data) {
        this.editItem = Object.assign(this.editItem, data)
        this.$emit( 'change', this.serviceList)
        this.serviceFeeCardDialog = false
      }
    }
  }
</script>
